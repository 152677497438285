import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import * as MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { SliceZone } from '@prismicio/react';
import Head from 'next/head';
import Link from 'next/link';
import { useContext, useEffect } from 'react';

import { useCampsiteTotalCountQuery } from '@/__generated__/public-graphql';
import constMeta from '@/contents/constants/constMeta';
import Card from '@/contents/General/Card';
import Icon from '@/contents/General/Icon';
import { Thumbnail } from '@/contents/General/Panels';
import Stack from '@/contents/General/Stack';
import Typography from '@/contents/General/Typography';
import { GlobalContext } from '@/contents/hooks/state';
import LayoutTop from '@/layouts/LayoutTop';
import { createClient } from '@/prismicio';
import { components } from '@/slices';
import theme from '@/styles/theme';
import type { NextPageWithLayout } from '@/types';
import { Page } from '@/types/domain';
import type { SlicesFetched } from '@/types/main';
import type {
  NotificationDocument,
  TopDocument,
} from '@/types/prismic.generated';
import DateToString from '@/utils/DateToString';
import FetchSlices from '@/utils/FetchSlices';
import getRevalidateSec from '@/utils/getRevalidateSec';

const MainVisual = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  min-height: 600px;
  background: url(/assets/images/common/bg_mainvisual.webp) no-repeat;
  background-size: cover;
  z-index: -1;
` as typeof Box;

const SearchButton = styled(MuiButton.default)`
  width: 246px;
  height: 48px;
  color: ${theme.palette.primary.main};
  font-size: 14px;
  font-weight: bold;
  border-radius: 24px;
  background: #ffffff;
`;

const Content = styled(Box)`
  position: relative;
  margin-top: -112px;
  z-index: 1;
`;

type Props = {
  page: TopDocument;
  slices: SlicesFetched;
  notifications: NotificationDocument[];
  totalCampSitesNum: number;
  title: string;
  metaDescription: string;
};

const Index: NextPageWithLayout<Props> = ({
  page,
  slices,
  notifications,
  totalCampSitesNum,
  title,
  metaDescription,
}) => {
  const { setPage } = useContext(GlobalContext);

  useEffect(() => {
    setPage(Page.TOP);
  }, []);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:url" content="https://iihi.life" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta
          property="og:image"
          content="https://iihi.life/assets/images/common/ogp.jpg"
        />
        <link
          href="/assets/images/common/bg_mainvisual.webp"
          as="image"
          rel="preload"
        />
      </Head>
      <MainVisual>
        <Stack
          column
          alignCenter
          sx={{ mt: theme.spacing(9), mb: theme.spacing(7) }}
        >
          <Typography fontSize={12} color="white" variant="h1">
            全国{totalCampSitesNum}件のキャンプ場検索サイト
          </Typography>
          <Box sx={{ marginTop: theme.spacing(3), marginBottom: '44px' }}>
            <img
              src="/assets/images/common/logo-white.svg"
              width={199}
              height={115}
              alt="iihi logo"
            />
          </Box>
          <Typography
            fontSize={14}
            color="white"
            textAlign="center"
            letterSpacing="0.24em"
          >
            みんなでつくる
            <br />
            スマホの中のキャンプギア
          </Typography>
        </Stack>
        <Link href="/search">
          <a>
            <SearchButton
              startIcon={<Icon iconType="search" />}
              variant="contained"
              disableElevation
              color="white"
            >
              キャンプ場、どこ行く？
            </SearchButton>
          </a>
        </Link>
      </MainVisual>
      <Content>
        <Box sx={{ paddingBottom: theme.spacing(6) }}>
          <Box sx={{ padding: `0 ${theme.spacing(3)}` }}>
            <Typography
              fontSize={21}
              color="white"
              fontWeight={700}
              variant="h2"
            >
              注目のトピック
            </Typography>
          </Box>
          <Stack
            space={2}
            sx={{
              overflowX: 'scroll',
              marginTop: theme.spacing(3),
              padding: `0 ${theme.spacing(3)}`,
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {
              // @ts-ignore
              (page.data.head_topics ?? []).map((item: any) => (
                <Card
                  key={`${item.link ?? ''} ${item.title ?? ''}`}
                  variant="inner-title"
                  cardSize="vt"
                  imageUrl={item.image.url}
                  title={item.title ?? ''}
                  link={item.link}
                />
              ))
            }
          </Stack>
        </Box>

        <Thumbnail image_height={42} max_height={214}>
          <SliceZone slices={slices} components={components} />
        </Thumbnail>

        <Box
          sx={{
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(7),
            padding: `0 ${theme.spacing(3)}`,
          }}
        >
          <Stack
            between
            sx={{
              paddingBottom: theme.spacing(3),
            }}
          >
            <Typography fontSize={18} fontWeight={700} variant="h2">
              お知らせ
            </Typography>
            <Link href={`/news`}>
              <a>
                <Typography fontSize={16} color="primary">
                  すべて見る
                </Typography>
              </a>
            </Link>
          </Stack>
          <Divider />
          {notifications.map((notification, index) => {
            const firstPublicationDate = new Date(
              notification.first_publication_date
            );
            return (
              <div key={notification.id}>
                <Link href={`/news/${notification.uid}`}>
                  <a>
                    <Stack
                      column
                      space={1}
                      sx={{
                        paddingTop: theme.spacing(2),
                        paddingBottom: theme.spacing(3),
                      }}
                    >
                      <Typography fontSize={14} color="gray">
                        {DateToString(firstPublicationDate)}
                      </Typography>
                      <Typography fontSize={14}>
                        {notification.data.title}
                      </Typography>
                    </Stack>
                    <Divider />
                  </a>
                </Link>
              </div>
            );
          })}
        </Box>
      </Content>
    </>
  );
};

Index.getLayout = (page) => <LayoutTop>{page}</LayoutTop>;

export default Index;

export async function getStaticProps({ previewData }: any) {
  const client = createClient({ previewData });

  const result = await client.getByType('top');
  if (result.results.length < 1 || !result.results[0]) {
    throw new Error('No topPage found');
  }
  const page: TopDocument = result.results[0];
  const slices = await FetchSlices(page.data.slices, client);
  const notifications = await client.getAllByType('notification', {
    orderings: {
      field: 'document.first_publication_date',
      direction: 'desc',
    },
    limit: 4,
  });

  const totalCampSitesNum =
    (await useCampsiteTotalCountQuery.fetcher()()).campsite_aggregate.aggregate
      ?.count ?? 0;

  const { title, mata_description: metaDescription } = constMeta.top;

  return {
    props: {
      page,
      slices,
      notifications,
      totalCampSitesNum,
      title,
      metaDescription,
    },
    revalidate: getRevalidateSec(),
  };
}
