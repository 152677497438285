import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { User } from '@sentry/nextjs';
import { addMinutes, isAfter, isBefore } from 'date-fns';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import React, { useCallback, useContext, useEffect } from 'react';

import DialogSwitcher from '@/contents/Dialogs/DialogSwitcher';
import BreadCrumb from '@/contents/General/BreadCrumb';
import Stack from '@/contents/General/Stack';
import { GlobalContext } from '@/contents/hooks/state';
import { BottomMenu } from '@/layouts/components/BottomMenu';
import { Footer } from '@/layouts/components/Footer';
import { Header } from '@/layouts/components/Header';
import { Container, PCArea } from '@/layouts/style';
import useGetLoginUser from '@/modules/User/user/useGetLoginUser';
import theme from '@/styles/theme';
import { Page } from '@/types/domain';

import PCNavi from './components/PCNavi';
import PCSuggest from './components/PCSuggest';

type Props = {
  children: ReactNode;
};

const LayoutTop = ({ children }: Props) => {
  const router = useRouter();
  const { user: loginUser } = useGetLoginUser();
  const { page, isBrowserBack, completeTutorial, setCompleteTutorial } =
    useContext(GlobalContext);
  // @ts-ignore
  const { props } = children;

  const isPC = useMediaQuery('(min-width:900px)');

  useEffect(() => {
    setCompleteTutorial(localStorage.getItem('complete_tutorial'));
  }, []);

  const isSignup = useCallback(() => {
    if (
      loginUser &&
      typeof loginUser === 'object' &&
      isAfter(
        new Date(),
        addMinutes(new Date((loginUser as User).createdAt as string), 10)
      )
    ) {
      return true;
    }
    if (loginUser && typeof loginUser === 'object' && completeTutorial) {
      return true;
    }
    if (!loginUser) {
      return true;
    }
    if (isBrowserBack) {
      return true;
    }
    if (
      loginUser &&
      typeof loginUser === 'object' &&
      completeTutorial === null
    ) {
      return false;
    }
    return true;
  }, [loginUser]);

  const routeSignupPage = useCallback(() => {
    if (
      loginUser &&
      typeof loginUser === 'object' &&
      isBefore(
        new Date(),
        addMinutes(new Date((loginUser as User).createdAt as string), 10)
      ) &&
      !completeTutorial
    ) {
      router.replace('/signup');
    }
  }, [loginUser]);

  useEffect(() => {
    routeSignupPage();
  }, [loginUser]);

  return (
    <>
      <Box>
        {isPC && (
          <PCArea>
            <Stack between>
              <PCNavi />
              <PCSuggest />
            </Stack>
          </PCArea>
        )}
        <Container>
          <Header />
          <main>
            {children}
            <Box
              sx={{
                marginTop: 'auto',
                marginBottom: theme.spacing(3),
                padding:
                  page === Page.THEME
                    ? `0 ${theme.spacing(3)}`
                    : `${theme.spacing(10)} ${theme.spacing(3)} 0`,
              }}
            >
              <BreadCrumb
                campSite={props?.campSite}
                user={props?.user}
                notification={props?.notification}
                post={props?.post}
                faq={props?.faq}
                faqCategory={props?.category}
                theme={props?.theme}
              />
            </Box>
            <Footer />
          </main>
          {!isPC && page !== Page.CAMPSITE && <BottomMenu />}
          <DialogSwitcher />
        </Container>
      </Box>
      {!isSignup() && (
        // <LoadingPanel>
        <Box width={133} height={77} />
        // </LoadingPanel>
      )}
    </>
  );
};

export default LayoutTop;
